@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/UrbanistSemiBold.ttf");
  font-family: "Urbanist";
  font-weight: 600;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/UrbanistRomanBold.ttf");
  font-family: "Urbanist";
  font-weight: 700;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/UrbanistRegular.ttf");
  font-family: "Urbanist";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/UrbanistRomanMedium.ttf");
  font-family: "Urbanist";
  font-weight: 500;
}
