body {
  margin: 0;
  padding: 0;
  font-family: Urbanist;
}

* {
  box-sizing: border-box;
  line-height: normal;
  font-family: inherit;
  margin: unset;
}

.sliderview .alice-carousel__dots {
  z-index {}

  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: auto;
  bottom: 5%;
  left: 0px;
  right: 0px;
  margin: auto;
}

.slider9fortyone .alice-carousel__dots {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: auto;
  bottom: 5%;
  left: 0px;
  right: 0px;
  margin: auto;
}

.slidermakepayme .alice-carousel__dots {
  z-index {}

  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 5%;
  right: 0px;
  left: 0px;
  margin: auto;
}

a {
  text-decoration: none;
  display: block;
}

.alice-carousel__dots-item {
  cursor: pointer;
}

.upload {
  position: relative;
  background-color: rgba(214, 182, 140, 0.08);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}

.upload img {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
}

.upload span {
  z-index: 10;
}

.text-arrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.btn-gold {
  color: white;
  background: linear-gradient(286.17deg, #D6B68C 0%, #744E1C 100%);
}

.btn-border-gold {
  color: #BC9A65;
  border: 2px solid #BC9A65;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.btn-border-gold.selected {
  color: #FFD700 !important;
  border-color: #FFD700 !important;

}

@keyframes rotation {
  0% {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(359deg)
  }
}

.loading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.loading img {
  animation: rotation 2s infinite linear;
}

.select-prompt {
  display: flex;
  border-radius: 0.5rem;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
}

.unselect-prompt {
  display: none;
}

.selected-color span,
.selected-color img {
  border: 2px solid #FFD700 !important;

}

.btn-border-gold.selected .format-square,
.btn-border-gold.selected .format-portrait,
.btn-border-gold.selected .format-rectangle,
.btn-border-gold.selected .format-quarter {
  border-color: #FFD700 !important;
}

.format-square {
  display: flex;
  border: 2px solid #BC9A65;
  height: 0.9rem;
  width: 0.9rem;
  margin-right: 5px;
}

.format-portrait {
  display: flex;
  border: 2px solid #BC9A65;
  height: 1.2rem;
  width: 0.675rem;
  margin-right: 5px;
}

.format-rectangle {
  display: flex;
  border: 2px solid #BC9A65;
  height: 0.675rem;
  width: 1.2rem;
  margin-right: 5px;
}

.format-quarter {
  display: flex;
  border: 2px solid #BC9A65;
  height: 1.2rem;
  width: 0.9rem;
  margin-right: 5px;
}

.menu-bottom a button {
  filter: invert(30%) sepia(0%) saturate(100%) hue-rotate(0) brightness(100%) contrast(100%);
}


.menu-bottom a.selected button {
  filter: none;
}